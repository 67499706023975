export const DATA_TUTORIAL_QUESTIONS = [
  {
    id: 0,
    title: 'Olá, vestibulando(a). Tudo bem com você?',
    text:
      '<p>Antes de começar a praticar, gostaríamos de explicar como funciona o nosso banco de questões.</p>'
  },
  {
    id: 1,
    title: 'Banco de Questões',
    text:
      '<p>Diferentemente do formato dos exercícios montados que possuímos nas disciplinas.<strong> Reunimos em um banco todas as nossas mais variadas questões</strong>, para que você possa revisá-las e praticá-las <strong>respondendo cada uma separadamente.</strong></p>'
  },
  {
    id: 2,
    title: 'Sobre as questões',
    text:
      '<p>Você também pode escolher as questões de acordo com as <strong>Disciplinas</strong> e os <strong>Assuntos</strong>.</p>',
    imgs: [
      {
        src: 'list-questions.svg',
        alt:
          'A imagem mostra a listagem de questões, com o filtro acima para filtrar questões de determinada disciplina e assunto'
      }
    ]
  },
  {
    id: 3,
    title: 'Questões Relacionadas',
    text:
      '<p>Além disso, mostramos questões que estão relacionadas para que você continue estudando de acordo com o tema selecionado.</p>',
    imgs: [
      {
        src: 'related-questions.svg',
        alt: 'A imagem mostra a seção de questões relacionadas à questão escolhida.'
      }
    ]
  },
  {
    id: 4,
    title: 'Conheceu nosso Banco de Questões?',
    text:
      '<p>Agora que já sabe tudo o que precisa saber sobre o nosso banco de questões. Vamos dar início aos seus estudos?</p>'
  }
];
